import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/LoginView.vue')
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('@/views/LogoutView.vue')
    },
    {
      path: '/',
      component: () => import('@/layout/AppLayout.vue'),
      children: [
        {
          path: '/',
          name: 'dashboard',
          component: () => import('@/views/DashboardView.vue')
        },
        {
          path: '/profile',
          name: 'profile',
          component: () => import('@/views/ProfileView.vue')
        },
        {
          path: '/card',
          children: [
            {
              path: '',
              name: 'card',
              component: () => import('@/views/Card/CardView.vue')
            },
            {
              path: 'new',
              name: 'cardNew',
              component: () => import('@/views/Card/CardNewView.vue')
            }
          ]
        },
        {
          path: '/design',
          name: 'design',
          component: () => import('@/views/Design/DesignView.vue')
        }
      ]
    }
  ]
})

export default router
