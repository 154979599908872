import axios from 'axios'

// Solicitar el token CSRF al cargar la aplicación
axios
  .get(import.meta.env.VITE_BASE_URL + '/sanctum/csrf-cookie', { withCredentials: true })
  .then(() => {
    // Ahora puedes hacer solicitudes seguras a tu API
  })

// Configurar Axios para enviar cookies con cada solicitud
axios.defaults.withCredentials = true
axios.defaults.baseURL = import.meta.env.VITE_BASE_URL

// Interceptor para agregar el token CSRF a todas las solicitudes
axios.interceptors.request.use(
  (config) => {
    const XSRFtoken = document.cookie
      .split('; ')
      .find((row) => row.startsWith('XSRF-TOKEN='))
      ?.split('=')[1]

    if (XSRFtoken) {
      config.headers['X-XSRF-TOKEN'] = decodeURIComponent(XSRFtoken)
    }
    const userToken = document.cookie
      .split('; ')
      .find((row) => row.startsWith('userToken='))
      ?.split('=')[1]

    if (userToken) {
      config.headers['Authorization'] = 'Bearer ' + decodeURIComponent(userToken)
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response?.status === 401) {
      window.location.href = '/login'
    }

    return Promise.reject(error)
  }
)
